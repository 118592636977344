import React, { useEffect, useRef, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/AppProvider";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import cc from "../../../img/cc.png";
import knet from "../../../img/knet.png";
import apple from "../../../img/apple.png";
import { v4 as uuidv4 } from "uuid";
import ViewData from "../../services/ViewData";
import HandelPaymentChampion from "../../handelPayment/HandelPaymentChampion";
import Loading from "../../loading/Loading";

const CompletePurchaseChampionship = ({
  product,
  setOpenPage,
  viewChampionship,
}) => {
  const [isDisabled, setDisabled] = useState(false);
  const { user, dataUser, direction } = useAppContext();
  const { i18n, t } = useTranslation();
  const [viewQuestions, setViewQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  let order_reference = uuidv4();
  const trueRef = useRef(true);

  if (order_reference.length > 35) {
    order_reference = order_reference.replace(/-/g, "");
    order_reference = order_reference.substring(0, 35);
  }

  const [answers, setAnswers] = useState([]);

  const [inputValue, setInputValue] = useState({
    nameUser: "",
    emailUser: "",
    mobileUser: "",
    addressUser: "",
    gender_user: "",
    team_or_individual_user: "",
  });

  const dataQuestionChampion = async () => {
    setLoading(true);

    try {
      const result = await ViewData(
        "question_champion.php",
        viewChampionship[0].id
      );
      setViewQuestions(result);

      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (trueRef.current) {
      dataQuestionChampion();
      trueRef.current = false;
    }
  }, []);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setInputValue((prevValue) => ({
      ...prevValue,
      mobileUser: value,
    }));
  };

  const handleClose = () => {
    setOpenPage(false);
  };

  const dataUserinfo = {
    uniqueId: order_reference,
    userId: user !== null ? dataUser.id : "",
    name: user !== null ? dataUser.full_name : inputValue.nameUser,
    email: user !== null ? dataUser.email_user : inputValue.emailUser,
    mobile: user !== null ? dataUser.phone_number : inputValue.mobileUser,
    address: inputValue.addressUser,
    gender_user: inputValue.gender_user,
    team_or_individual_user: inputValue.team_or_individual_user,
  };

  // console.log(dataUserinfo);

  const handleInputChange = (e, index) => {
    const question = viewQuestions[index].question_en;
    const idChampion = viewChampionship[0].id;

    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = { question, answer: e.target.value, idChampion };
      return updatedAnswers;
    });
  };

  const handlePay = async (e) => {
    e.preventDefault();
    if (dataUserinfo.mobile.length > 5) {
      setDisabled(true);

      try {
        const result = await HandelPaymentChampion(
          "order championship",
          product,
          dataUserinfo,
          "order_championship.php",
          paymentMethod,
          order_reference,
          answers
        );
        // console.log(result);

        if (result.message.includes("free")) {
          toast.success(t("successfully_registered"));
          setOpenPage(false);
        } else if (result.message.includes("successfully")) {
          window.location.href = result.data.link;
          localStorage.removeItem("basket");
        } else {
          toast.error(t("error_payment"));
          console.log(result);
          setDisabled(false);
        }
      } catch (error) {
        toast.error(t("error_payment"));
        console.log(error);
        setDisabled(false);
      }
    } else {
      toast.warning(t("phone_number_failed"));
    }
  };

  // console.log(paymentMethod);
  // console.log(product);
  // console.log(viewChampionship[0]);
  // console.log(viewQuestions);
  // console.log(answers);

  return (
    <div className="position_fixed payment_pay">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />
      <div className="overflow_pages">
        <div className="left_right">
          <div className="right" style={{ direction: direction }}>
            <form onSubmit={handlePay} method="post">
              <div>
                <div className="right">
                  {user === null && (
                    <>
                      <fieldset>
                        <legend>{t("full_name")}</legend>

                        <input
                          type="text"
                          value={inputValue.nameUser}
                          onChange={handleChange}
                          placeholder={t("full_name")}
                          required
                          className="input"
                          name="nameUser"
                        />
                      </fieldset>

                      <fieldset>
                        <legend>{t("email")}</legend>

                        <input
                          type="email"
                          value={inputValue.emailUser}
                          onChange={handleChange}
                          placeholder={t("email")}
                          required
                          className="input"
                          name="emailUser"
                        />
                      </fieldset>

                      <fieldset>
                        <legend>{t("phone_number")}</legend>

                        <PhoneInput
                          placeholder={t("phone_number")}
                          country={"kw"}
                          className="input"
                          value={inputValue.mobileUser}
                          onChange={handlePhoneChange}
                          inputProps={{
                            required: true,
                            name: "mobileUser",
                          }}
                        />
                      </fieldset>
                    </>
                  )}

                  {product[0]?.price > 0 && (
                    <fieldset>
                      <legend>{t("type_payment_method")}</legend>

                      <div className="payment_method">
                        <label
                          htmlFor="payment_method_1"
                          className={
                            paymentMethod === "cc" ? "active_checked" : ""
                          }
                        >
                          <img src={cc} alt="cc" />
                          <input
                            type="radio"
                            value="cc"
                            onChange={handlePaymentMethodChange}
                            name="payment_method"
                            id="payment_method_1"
                            required
                          />
                        </label>

                        <label
                          htmlFor="payment_method_2"
                          className={
                            paymentMethod === "knet" ? "active_checked" : ""
                          }
                        >
                          <img src={knet} alt="knet" />
                          <input
                            type="radio"
                            value="knet"
                            onChange={handlePaymentMethodChange}
                            name="payment_method"
                            id="payment_method_2"
                          />
                        </label>
                        <label
                          htmlFor="payment_method_3"
                          className={
                            paymentMethod === "apple-pay"
                              ? "active_checked"
                              : ""
                          }
                        >
                          <img src={apple} alt="apple" />
                          <input
                            type="radio"
                            value="apple-pay"
                            onChange={handlePaymentMethodChange}
                            name="payment_method"
                            id="payment_method_3"
                          />
                        </label>
                      </div>
                    </fieldset>
                  )}
                </div>

                <div className="left">
                  {error ? (
                    "Error"
                  ) : load ? (
                    <Loading />
                  ) : (
                    viewQuestions.length > 0 &&
                    viewQuestions?.map((q, i) => (
                      <fieldset className="answer" key={i}>
                        <legend>{`${t("question")} ${i + 1}`}</legend>

                        <p>
                          {i18n.language === "en"
                            ? q.question_en
                            : q.question_ar}
                        </p>

                        <input
                          type="text"
                          name={`answer_${i}`}
                          placeholder={`${t("answer")}`}
                          onChange={(e) => handleInputChange(e, i)}
                          required
                        />
                      </fieldset>
                    ))
                  )}

                  {viewChampionship[0].gender_user === "1" && (
                    <fieldset>
                      <legend>{t("gender_user")}</legend>
                      <div className="payment_method radio">
                        <label
                          htmlFor="gender_user_1"
                          className={
                            inputValue.gender_user === "male"
                              ? "active_checked"
                              : ""
                          }
                        >
                          <p>{t("male")}</p>

                          <input
                            type="radio"
                            value="male"
                            onChange={handleChange}
                            name="gender_user"
                            id="gender_user_1"
                            required
                          />
                        </label>

                        <label
                          htmlFor="gender_user_2"
                          className={
                            inputValue.gender_user === "feminine"
                              ? "active_checked"
                              : ""
                          }
                        >
                          <p>{t("feminine")}</p>
                          <input
                            type="radio"
                            value="feminine"
                            onChange={handleChange}
                            name="gender_user"
                            id="gender_user_2"
                          />
                        </label>
                      </div>{" "}
                    </fieldset>
                  )}
                  {viewChampionship[0].team_or_individual_user === "1" && (
                    <fieldset>
                      <div className="payment_method radio">
                        <label
                          htmlFor="team_or_individual_user_1"
                          className={
                            inputValue.team_or_individual_user === "individual"
                              ? "active_checked"
                              : ""
                          }
                        >
                          <p>{t("individual")}</p>

                          <input
                            type="radio"
                            value="individual"
                            onChange={handleChange}
                            name="team_or_individual_user"
                            id="team_or_individual_user_1"
                            required
                          />
                        </label>

                        <label
                          htmlFor="team_or_individual_user_2"
                          className={
                            inputValue.team_or_individual_user === "team"
                              ? "active_checked"
                              : ""
                          }
                        >
                          <p>{t("team")}</p>
                          <input
                            type="radio"
                            value="team"
                            onChange={handleChange}
                            name="team_or_individual_user"
                            id="team_or_individual_user_2"
                          />
                        </label>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="button"
                disabled={isDisabled}
                style={{ opacity: isDisabled ? 0.3 : "" }}
              >
                {isDisabled
                  ? t("waiting")
                  : product[0]?.price > 0
                  ? t("pay")
                  : t("register_now")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletePurchaseChampionship;
