export const englishDataEn = {
  home: "Championships",
  about_us: "About us",
  privacy_policy: "Privacy Policy",
  store: "Store",
  support_assistance: "Contact Us",
  full_name: "full name",
  phone_number: "Mobile number",
  phone_number_failed: "You must enter a valid phone number",
  email: "E-mail",
  message: "message",
  submit: "Submit",
  send_message: "send",
  waiting: "waiting",
  has_been_sent: "has been sent",
  error_occurred_while_sending: "An error occurred while sending",
  currency: "KWD",
  coming_soon: "Coming Soon",
  confirmed: "Confirmed",
  more: "More",
  loading: "Loading...",
  no_data: "No Data",
  add_basket: "Add to cart",
  already_inside_cart: "The product is already in the basket",
  an_error_occurred: "An Error Occurred",
  total: "Total",
  basket_empty: "The basket is empty",
  pay: "Pay",
  password: "Password",
  logged_in_successfully: "Logged in successfully",
  user_not_registered: "User is not registered",
  the_password_incorrect: "The password is incorrect",
  register_account: "Register a new account",
  forgot_your_password: "Forgot your password",
  email_not_confirmation: "The account is not activated",
  login: "Login",
  email_already_registered: "Email is already registered",
  successfully_registered: "successfully registered",
  verification_code_email: "The verification code has been sent to your email",
  resend: "Resend",
  resend_verification_code_after: "Resend the verification code after",
  verification_code_invalid: "The verification code is invalid",
  account_has_been_confirmed: "The account has been confirmed successfully",
  change_password: "Change password",
  password_changed_successfully: "The password has been changed successfully",
  user_information: "User information",
  purchase_history: "Purchase history",
  dashboard_user: "Dashboard",
  save_data: "Save",
  updated_successfully: "Updated Successfully",
  date_of_registration: "date of registration",
  subscription: "Subscription",
  successfully_payment: "Payment completed successfully",
  error_payment: "An error occurred during the payment process",
  payment_Date: "Payment Date",
  payment_status: "Payment status",
  successful_payment: "Successful payment",
  failed_payment: "Failed payment",
  payment_code: "Payment code",
  product_details: "Product details",
  recipient_address: "Recipient's address",
  whats_app: "WhatsApp",
  type_payment_method: "Type of payment method",
  question: "Question",
  answer: "Answer",
  gender_user: "Gender",
  individual: "Individual",
  team: "Team",
  male: "Male",
  feminine: "Feminine",
  user_details: "User details",
  products_details: "Products details",
  tournament_details: "Tournament details",
  full_subscribers: "Full",
  register_now: "Register now",
};

export default englishDataEn;
